
import stores from "@/stores";
import { __SignIn } from "@/stores/auth/action-dto";
import { defineComponent } from "vue";
import ISignInForm from "@/types/forms/sign-in-form.type";

/* components */
import CInputLogin from "@/components/shared/input/CInputLogin.vue";
import CCheckBox from "@/components/shared/checkbox/CheckBox.vue";

/* library toast */
import "vue3-toastify/dist/index.css";

/* Service */
import ToastService from "@/services/Toast.service";
import ValidatorsService from "@/services/Validators.service";
import shareHelper from "@/components/shared/helper/shareHelper";

export default defineComponent({
  /* name */
  name: "SignInForm",

  /* components */
  components: {
    CInputLogin,
    CCheckBox,
  },

  /* props */
  props: {
    getSignInError: {
      type: Number,
      require: true,
    },
  },

  /* data */
  data() {
    return {
      signInForm: {
        username: "",
        password: "",
        keep_me_logged_in: true,
      } as ISignInForm,
      status: {
        loginRound: 0,
        valid: false,
      },
      inputValue: "",
    };
  },

  /* methods */
  methods: {
    onLogin() {
      const { username, password } = this.signInForm;
      const isUsernameEmpty = shareHelper.checkMaxLength(username, 0);
      const isPasswordEmpty = shareHelper.checkMaxLength(password, 0);

      if (isUsernameEmpty && isPasswordEmpty) {
        this.status = { ...this.status, valid: true };
        return ToastService.showWarning("Please input your email and password");
      } else if (isUsernameEmpty) {
        return ToastService.showWarning("Please input your email");
      } else if (isPasswordEmpty) {
        return ToastService.showWarning("Please input your password");
      }
      /* Sign In */
      const payload: __SignIn = { ...this.signInForm };
      stores.dispatch("auth/signIn", payload);
    },
    checkKeyboard(el, type) {
      if (type === "text" || type === "password") {
        if (type === "text") {
          this.signInForm.username = el;
        } else if (type === "password") {
          this.signInForm.password = el;
        }
      }
      /* check keyboard 5 character */
      const checkKeyboard = shareHelper.checkKeyboard(el);
      if (!checkKeyboard) return;
      ToastService.showWarning("Your keyboard is not set to English");
    },
    checkEmail(el) {
      const value = el.target.value;
      if (value && ValidatorsService.validateEmail(value)) {
        if (shareHelper.checkMaxLengthEmail(this.signInForm.username)) {
          return;
        }
        return;
      } else {
        ToastService.showWarning(
          "Incorrect email format. Please recheck again."
        );
      }
    },
    onForgotPassword() {
      const baseUrl = process.env.VUE_APP_BASE_URL_PEAK;
      const username = this.signInForm.username;
      window.open(`${baseUrl}/forgotPwd?a=${username}`, "_blank");
    },
  },
  /* watch */
  watch: {
    getSignInError(newValue) {
      if (newValue !== null) {
        this.status.loginRound++;
        ToastService.showError(newValue);
        if (this.status.loginRound >= 2) {
          ToastService.showWarning(
            "Cannot log in? Please click forgot password to set a new one."
          );
        }
      }
    },
  },
  /* computed */
  computed: {},
});
